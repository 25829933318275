<template>
<div class="popup_wrpr">
	<div class="popup__window" @click.self.stop="$emit('close')">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<h2>{{ editCallData ? $translate('update-a-call') : $translate('schedule-a-call') }}</h2>
				<a class="popup__closer" @click="$emit('close')">
					<inline-svg :src="require('@/assets/img/close-icon.svg')" class="pointer-events-none" />
				</a>
			</div>
			<div class="popup__window-body">
				<div class="schedule-call">
					<div class="schedule-call__col schedule-call__col--left">
						<h4 class="schedule-call__title">{{ $translate('select-date') }}</h4>
						<div class="schedule-call__group">
							<div class="schedule-call__item" v-for="item in preselectDate" :key="item">
								<a class="btn btn-action btn-standart-2" @click="setDate(item)">
									<span>{{ item }}</span>
								</a>
							</div>
						</div>
						<div class="inline-picker">
							<DatePicker
								displayMode="inline"
								v-model="selectedDate"
							/>
						</div>
					</div>
					<div class="schedule-call__col schedule-call__col--right">
						<h4 class="schedule-call__title">{{ $translate('available-time') }}</h4>
						<div class="schedule-call__group">
							<div class="schedule-call__item" v-for="(item, index) in timeArrayFiltered" :key="index">
								<div class="form-radio">
									<input
										:id="`form-radio-${index}`" type="radio" class="form-radio__input" name="schedule-call"
										style="display: none;" :value="`${ item.time }, ${ item.type }`" v-model="selectedTime"
									>
									<label :for="`form-radio-${index}`" class="form-radio__label">
										{{ item.time }} <span class="form-radio__small">{{ item.type }}</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="popup__window-actions">
				<a class="btn btn-w-shadow btn-standart-2 popup__closer" @click="$emit('close')">
					<span>{{ $translate('cancel') }}</span>
				</a>
				<div class="tooltip_alert_wrpr">
                    <span class="tooltip_alert" v-if="!filledsNotEmpty">{{ $translate('select-date-and-time') }}</span>
					<a class="btn btn-primary btn-standart-2" :class="{'disabled': !filledsNotEmpty}" @click="create" v-if="!editCallData">
						<span>{{ $translate('schedule-a-call') }}</span>
					</a>
					<a class="btn btn-primary btn-standart-2" :class="{'disabled': !filledsNotEmpty}" @click="update" v-if="editCallData">
						<span>{{ $translate('update-a-call') }}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Base from '@/components/base';
import { API } from "aws-amplify";
import { createUpcomingCalls, updateUpcomingCalls } from "@/graphql/mutations";
import { listUpcomingCalls } from '@/graphql/queries';

export default {
    name: 'ScheduleACallPopup',
	components: {
		...Base,
	},
	props: ['editCallData'],
	data(){
		return {
			selectedDate: '',
			preselectDate: ["Today", "Tomorrow", "In 2 days"],
			selectedTime: '',
			timeArray: [
				{ time: '1:00', type: 'PM', },
				{ time: '2:00', type: 'PM', },
				{ time: '3:00', type: 'PM', },
				{ time: '4:00', type: 'PM', },
				{ time: '5:00', type: 'PM', },
				{ time: '6:00', type: 'PM', },
				{ time: '7:00', type: 'PM', },
				{ time: '8:00', type: 'PM', },
				{ time: '9:00', type: 'PM', },
				{ time: '10:00', type: 'PM', },
			],
			calls: [],
		}
	},
	computed: {
		userData() {
			return this.$store.state.userData || {}
		},
        filledsNotEmpty(){
            return this.selectedDate.length && this.selectedTime.length;
        },
		timeArrayFiltered(){
			if(this.calls.length && this.selectedDate.length && this.calls.map(itemC => itemC.date).includes(this.selectedDate)){
				return this.timeArray.filter(itemF => !this.calls.map(item => item.time).includes(`${itemF.time}, ${itemF.type}`));
			} else {
				return this.timeArray;
			}
		}
	},
	methods: {
		setDate(type){
			let today = new Date();

			if(type === 'Tomorrow') today.setDate(today.getDate() + 1);
			if(type === 'In 2 days') today.setDate(today.getDate() + 2);

			this.selectedDate = `${ this.checkMinLength(today.getDate()) }.${ this.checkMinLength(today.getMonth()+1) }.${ today.getFullYear() }`;
		},
		checkMinLength(value){
			if((value+'').length === 1){
				return '0' + value;
			} else {
				return value;
			}
		},
		async create(){
            await API.graphql({
                query: createUpcomingCalls,
                variables: {
                    input: {
                        date: this.selectedDate,
                        time: this.selectedTime,
                        user: this.userData.id,
                    },
                },
            });

            this.$emit('create');
		},
		async update(){
			const options = { title: "Save changes?", size: "sm", okLabel: "Save", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
					await API.graphql({
						query: updateUpcomingCalls,
						variables: {
							input: {
								id: this.editCallData.id,
								date: this.selectedDate,
								time: this.selectedTime,
							},
						},
					});

					this.$emit('create');
    			}
            });

		},
	},
    async created(){
		const filter = {
			user: {
				eq: this.userData.id
			}
		};

		this.calls = await API.graphql({
			query: listUpcomingCalls,
			variables: {
				filter
			},
		}).then((res) => res.data.listUpcomingCalls.items);

        if(this.editCallData){
            this.selectedDate = this.editCallData.date;
			this.selectedTime = this.editCallData.time;
        }
    }
}
</script>